@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


body {
  margin: 0;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  color: #0f0f0f;
}

:focus {
  outline: none;
}
* {
  scrollbar-width: thin;
}
a {
  text-decoration: none;
  color: black;
}

h1{
  font-size: 2.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  margin-top: 0;
}
h2{
  font-size: 1.2em;
  font-weight: bold;
}
p{
  font-size: 1.1em;
}